.content {
  max-width: 1000px;
  padding-top: var(--m);
  flex-grow: 1;
  height: 100%;

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: var(--m);

    .tableWrapper {
      overflow-y: auto;
      padding: 1px;
      margin-right: -20px;
      padding-right: 21px;
    }

    .actions {
      display: flex;
      justify-content: space-between;
    }
  }
}

.samples {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
  white-space: nowrap;

  & > small {
    background-color: var(--color-input-background);
    font-family: monospace;
    padding: var(--m-xxxxs);
    border-radius: var(--border-radius-1);
    font-size: var(--font-size-xs);
    display: inline-block;

    & + small {
      margin-left: var(--m-xxxxs);
    }
  }
}
.spinner {
  border: 1px solid var(--color-border);
  margin-top: var(--m);
  padding: var(--m);
  border-radius: var(--border-radius-1);
}
.errorContainer {
  display: flex;
  justify-content: center;
}